.required, span.error {
  color: #ff0000;
}

.card .rt-table label {
  color: #000;
}

.checkedActions {
  width: 120px;
}

.id-input::-webkit-input-placeholder {
  text-transform: none !important;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  flex: 1, 0;
  -webkit-flex: 1, 0;
}

.ReactTable .-pagination button {
  height: auto;
}
.table-responsive {
  overflow: auto  !important;
  padding-bottom: 10px;
  max-height: 500px;
}
.modal-content > .modal-header {
  /* Basically className="row" in bootstrap */
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-wrap: wrap !important;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  /* margin-left: -15px; */

  /* Not bootstrap */
  padding-left: 50px;
  padding-right: 50px;
}
.modal-header .modal-title {
  text-transform: capitalize;
  font-size: 16px;
  color: black;
}
.modal-header .close {
  align-self: center;
}
.modal-content > .modal-footer {
  padding-left: 50px;
  padding-right: 50px;
}
#customTable td,th {
  border-right:1px solid #80808029;
  white-space:pre;width:200px;word-break: break-word;white-space: pre;
text-align: center;
}
#customTable .react-select {
  width:400px;
}

#customTable .table .form-check {
  flex-direction: column;
  align-items: baseline;
}

i.lg {
  font-size: 12pt;
}


.selectmultiple .btn:disabled, .selectmultiple .btn[disabled], .selectmultiple .btn.disabled {
  background: none;
  color: #aaa;
  border: 1px solid #555;
}
.ReacctTable *{
  text-align: center !important;;
}
.rt-resizable-header-content{
  text-align: left;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: hidden !important;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  flex: none !important;
  -webkit-flex: none !important;
}


.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  white-space: normal;
  line-height: 1.5em !important;
}

.customTab .active.nav-link{
  color: white !important;
    background-color: grey !important;
}
.customTab .active.nav-link::after, .active.nav-link::before{
  display: none !important;
}
.customTab.nav.nav-tabs{
  justify-content: start;
  margin-bottom: 15px;;
  background-color: gainsboro;
}
.customTab .nav-link{
 cursor: pointer !important;
}
/* 
.nav-tabs .nav-item {
  background: #aaaaaa;
  border: 1px solid #999999;
}

.nav-tabs .nav-item .active {
  background: #ececec;
} */

.nav-tabs .nav-item .nav-link {
  cursor: pointer;
}
.profileImage{
  height:200px;
  width:200px;
  display: block;
  margin: auto;
}

.nav-tabs .nav-item .nav-link.active::before, .nav-tabs .nav-item .nav-link.active:hover::before, .nav-tabs .nav-item .nav-link.active:focus::before {
  bottom: -2px !important;
}
.nav-tabs .nav-item .nav-link.active::after, .nav-tabs .nav-item .nav-link.active:hover::after, .nav-tabs .nav-item .nav-link.active:focus::after {
  bottom: -2px !important;
}

select option {
  white-space:pre-wrap;
}

.login-page #root {
  background: url(../img/background.3c30c7c5.png) no-repeat;
  background-size: cover;
}

.login-page {
  background: none;
}

.login-page .full-page::after {
  display: none;
}

.login-page .section-image::after {
  display: none;
}

.login-page .full-page .footer {
  display: none;
}

.globalNotice {
  margin-top: 57px;
  margin-bottom: 0;
}

.main-panel > .content {
  margin-top: 0 !important;
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: grey;
  margin: 5px 0;
}

.navbar-toggler {
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin-right: 5px;
}

textarea.form-control {
  max-height: none !important;
}

.ps__rail-y, .ps__thumb-y {
  display: none !important;
} 
