/*!

 =========================================================
 * Paper Dashboard PRO React - v1.2.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "paper-dashboard/variables";
@import "paper-dashboard/mixins";

// Plugins CSS
@import "paper-dashboard/plugins/plugin-bootstrap-switch";
@import "paper-dashboard/plugins/plugin-bootstrap-table";
@import "paper-dashboard/plugins/plugin-nouislider";
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";
@import "paper-dashboard/plugins/plugin-sweetalert2";
@import "paper-dashboard/plugins/plugin-card-wizard";
@import "paper-dashboard/plugins/plugin-datetimepicker";
@import "paper-dashboard/plugins/plugin-bootstrap-select";
@import "paper-dashboard/plugins/plugin-jasny-fileupload";
@import "paper-dashboard/plugins/plugin-tagsinput";
@import "paper-dashboard/plugins/plugin-datatables.net";
@import "paper-dashboard/plugins/plugin-jquery.jvectormap";
@import "paper-dashboard/plugins/plugin-fullcalendar";

// Core CSS
@import "paper-dashboard/nucleo-outline";
@import "paper-dashboard/buttons";
@import "paper-dashboard/social-buttons";
@import "paper-dashboard/animated-buttons";
@import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/misc-extend";
@import "paper-dashboard/navbar";
@import "paper-dashboard/dropdown";
@import "paper-dashboard/alerts";
@import "paper-dashboard/images";
@import "paper-dashboard/tables";
@import "paper-dashboard/footers";
@import "paper-dashboard/fixed-plugin";

// components
@import "paper-dashboard/checkboxes-radio";
@import "paper-dashboard/progress";
@import "paper-dashboard/badges";
@import "paper-dashboard/pagination";
@import "paper-dashboard/info-areas";
@import "paper-dashboard/pills";
@import "paper-dashboard/tabs";
@import "paper-dashboard/popups";
@import "paper-dashboard/modals";
@import "paper-dashboard/carousel";
@import "paper-dashboard/sidebar-and-main-panel";
@import "paper-dashboard/timeline";

// cards
@import "paper-dashboard/cards";
@import "paper-dashboard/cards/card-chart";
@import "paper-dashboard/cards/card-map";
@import "paper-dashboard/cards/card-user";

@import "paper-dashboard/cards/card-background";
@import "paper-dashboard/cards/card-collapse";
@import "paper-dashboard/cards/card-contributions";
@import "paper-dashboard/cards/card-info-area";
@import "paper-dashboard/cards/card-lock";
@import "paper-dashboard/cards/card-pricing";
@import "paper-dashboard/cards/card-profile";
@import "paper-dashboard/cards/card-signup";
@import "paper-dashboard/cards/card-stats-mini";
@import "paper-dashboard/cards/card-stats";
@import "paper-dashboard/cards/card-subcategories";
@import "paper-dashboard/cards/card-testimonials";
@import "paper-dashboard/cards/card-tasks";

// example pages and sections
@import "paper-dashboard/example-pages";
@import "paper-dashboard/sections";

@import "paper-dashboard/responsive";
@import "paper-dashboard/media-queries";

// react differences
@import "paper-dashboard/react/react-differences";

.float-right {
    float: right !important;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
}

.pagination-bottom {
    margin: 16px 0;
    justify-content: flex-end;
    float: right;

    .-pagination {
        max-width: 350px;
        margin-right: 0;
        margin-left: auto;
        .-previous {
            max-width: 50px;
            max-height: 50px;
        }

        .-next {
            max-width: 50px;
            max-height: 50px;
        }

        .-center {
            max-width: 120px;
            width: 70px;
            max-height: 50px;
            .react-select
                > .react-select__control
                > .react-select__value-container {
                width: 70px;
            }
        }
    }
}

.col-form-label,
.react-select__placeholder,
.react-select__menu,
.react-select__single-value,
.select__placeholder,
.select__single-value,
.select__menu,
option,
input::placeholder,
table {
    text-transform: uppercase !important;
    font-size: 16px !important;
}

body,
input,
.form-control {
    font-size: 16px !important;
}

.btn-outline {
    background-color: #ffffff;
    color: #807f7f;
    border: 1px solid #807f7f;
    height: 40px !important;
    min-width: 120px !important;
    font-size: 14px !important;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active,
.btn-outline:active:focus,
.btn-outline:active:hover,
.btn-outline.active:focus,
.btn-outline.active:hover,
.show > .btn-outline.dropdown-toggle,
.show > .btn-outline.dropdown-toggle:focus,
.show > .btn-outline.dropdown-toggle:hover {
    background-color: #807f7f !important;
    color: #ffffff !important;
    border: 1px solid #807f7f !important;
    font-size: 14px !important;
}

.btn-search {
    background-color: #0070c0;
    color: #ffffff;
    height: 40px !important;
    min-width: 120px !important;
    font-size: 14px !important;
}

.btn-search:hover,
.btn-search:focus,
.btn-search:active,
.btn-search.active,
.btn-search:active:focus,
.btn-search:active:hover,
.btn-search.active:focus,
.btn-search.active:hover,
.show > .btn-search.dropdown-toggle,
.show > .btn-search.dropdown-toggle:focus,
.show > .btn-search.dropdown-toggle:hover {
    background-color: #0266ad !important;
    color: #ffffff !important;
    border: 1px solid #0266ad !important;
    font-size: 14px !important;
}

.btn-export {
    background-color: #4473c4;
    color: #ffffff;
    height: 40px !important;
    min-width: 120px !important;
    font-size: 14px !important;
}

.btn-export-custom {
    margin-right: 66px;
}

.btn-export-right {
    margin-right: -70px !important;
}

.btn-export-right-2 {
    margin-right: -60px !important;
}

.btn-export:hover,
.btn-export:focus,
.btn-export:active,
.btn-export.active,
.btn-export:active:focus,
.btn-export:active:hover,
.btn-export.active:focus,
.btn-export.active:hover,
.show > .btn-export.dropdown-toggle,
.show > .btn-export.dropdown-toggle:focus,
.show > .btn-export.dropdown-toggle:hover {
    background-color: #3862ab !important;
    color: #ffffff !important;
    border: 1px solid #3862ab !important;
    font-size: 14px !important;
}

.btn-add {
    background-color: #0c3864;
    color: #ffffff;
    font-size: 14px !important;
    height: 40px !important;
    min-width: 120px !important;
}

.btn-add:hover,
.btn-add:focus,
.btn-add:active,
.btn-add.active,
.btn-add:active:focus,
.btn-add:active:hover,
.btn-add.active:focus,
.btn-add.active:hover,
.show > .btn-add.dropdown-toggle,
.show > .btn-add.dropdown-toggle:focus,
.show > .btn-add.dropdown-toggle:hover {
    background-color: #092c4f !important;
    color: #ffffff !important;
    border: 1px solid #092c4f !important;
    font-size: 14px !important;
}

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
    color: #056fc0;
}

// .caret{

//     border: solid black;
//     border-width: 0 3px 3px 0;
//     display: inline-block;
//     padding: 3px;

//     transform: rotate(-135deg);
//   -webkit-transform: rotate(-135deg);
// }

// .dropdown-toggle:after, a .caret, .card-collapse .card a i {

//     -webkit-transform: rotate(45deg)!important;
//     transform: rotate(45deg)!important;
// }

.sidebar-normal {
    font-size: 16px !important;
}

.nav > .active > a > p,
.nav > li > a > p {
    font-size: 17px !important;
}

.react-select.primary
    .react-select__control
    .react-select__value-container--has-value
    .react-select__single-value,
.react-select.primary
    .react-select__control
    .react-select__value-container--has-value
    .react-select__multi-value__label {
    color: #000000 !important;
}

.react-select.primary .react-select__control.react-select__control--is-focused,
.react-select.primary
    .react-select__contro.react-select__control--is-focused:hover {
    border-color: #000000 !important;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.selectmultiple > div > button {
    width: 52px !important;
}

.btn-wide {
    min-width: 200px !important;
}

.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before {
    display: none;
}

.sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after {
    display: none;
}

.pagination-bottom
    > .-pagination
    > .-center
    > .container
    > .react-select
    > .react-select__menu {
    width: 100px !important;
    max-width: 100px !important;
    right: -30px !important;
}

@import "../css/base.css";

.badge-success {
    @include badge-color(green!important);
}

.badge-danger {
    @include badge-color(red!important);
}

textarea {
    line-height: normal !important;
}

// input placeholder color
// https://stackoverflow.com/questions/2610497/change-a-html5-inputs-placeholder-color-with-css
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #9A9A9A !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #9A9A9A !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #9A9A9A !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #9A9A9A !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #9A9A9A !important;
}
::placeholder { /* Most modern browsers support this now. */
   color:    #9A9A9A !important;
}

// Match it with react-select selected value
input {
    color: black !important;
}
